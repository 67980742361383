import { MutableState, Tools } from 'final-form';
import * as React from 'react';
import { useLazyQuery, useMutation } from 'react-apollo';
import * as Final from 'react-final-form';
import { Message, Grid, Icon, Table, Form, Dimmer, Popup, Loader } from 'semantic-ui-react';
import {
  BalanceType,
  CommChannel,
  Currency,
  RequestMeterInfo,
} from '../../../../__generated__/globalTypes';
import { PortalModal } from './portal-modal/index';
import {
  findBadUserInputErrors,
  fomatTo2DecimalPlaces,
  formatMoney,
  formatStringAsMoney,
  parseFloatWithCommas,
  penceToPound,
  usabillaPopup,
  validateAndFormatPhoneNumber,
} from '../../../../helpers';
import { debounce } from 'lodash';
import { toMoneyFormat, calculateDaysDifference, filterTypename } from '../../../../helpers';
import '../../forms/adjust-balance/adjust-balance.form.less';
import {
  addDiscretionaryCreditV3Mutation as Results,
  addDiscretionaryCreditV3MutationVariables as Variables,
} from '../../forms/add-discretionary-credit/__generated__/addDiscretionaryCreditV3Mutation';
import { addDiscretionaryCreditV3Mutation } from '../../forms/add-discretionary-credit/add-discretionary-credit.form.gql';
import '../../account-balance/account-balance.view.less';
// import { trackDcWizardV2 } from '../../../../metrics';
import { useContext } from 'react';
import { ClientStateContext } from '../../../../client/client-state-context';
import './ModalForm.less';
import ResultsPage from './ResultsPage';
import {
  recommendedDiscretionaryCreditQuery as recommendedDCQueryResults,
  recommendedDiscretionaryCreditQueryVariables as recommendedDCQueryVariables,
  recommendedDiscretionaryCreditQuery_recommendedDiscretionaryCredit as RecommendedDCInfo,
} from '../../forms/add-discretionary-credit/__generated__/recommendedDiscretionaryCreditQuery';
import { recommendedDiscretionaryCreditQuery } from '../../forms/add-discretionary-credit/recommended-discretionary-credit.gql';

import {
  jobDetailsQuery as JobDetailsQuery,
  jobDetailsQueryVariables as JobDetailsQueryVariables,
  jobDetailsQuery_job as JobDetails,
} from '../../forms/add-discretionary-credit/__generated__/jobDetailsQuery';
import { jobDetailsQuery } from '../../forms/add-discretionary-credit/job-details-by-job-id.gql';

import { format } from 'date-fns';
import {
  accountBalanceQuery_account_balances as MeterInfoType,
  accountBalanceQuery_account_debts as MeterDebInfo,
} from '../__generated__/accountBalanceQuery';
import * as uuid from 'uuid';
import { formatVulnerabilities } from '../../forms/add-discretionary-credit/helpers';
import { SingleDatePicker } from '../../../../components/date-pickers/single-date-picker';
import { trackFinancialSupport } from '../../../../metrics';

interface Props {
  accountId: string;
  onClose: () => void;
  balances: MeterInfoType[];
  debts: MeterDebInfo[];
  customerVulnerabilities?: string[] | null;
}
interface ModalForm {
  amount: string;
  totalDCGAS: string;
  totalDCELECTRICITY: string;
  repaymentRateGAS: string;
  repaymentRateELECTRICITY: string;
  daysRequired: string;
  metersSelected: string;
  contactChannel: CommChannel;
  mobileNumber: string;
  utrnSelection: string;
}

type DCFeedBackScheme = 'ok' | 'warning' | 'error';

export interface MeterInfo {
  msn: string;
  mpxn: string;
  repaymentRate?: number;
  adjustable: boolean;
  type: BalanceType;
  usualRepaymentRate?: number;
}

function getDateXDaysIntoFuture(x: number) {
  const today = new Date(); // Get the current date
  const futureDate = new Date(today);
  futureDate.setDate(today.getDate() + x); // Add 'x' days to the current date

  return futureDate;
}

interface InputProps {
  name: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement> | any) => void;
}
interface DatePickerProps {
  input: InputProps;
  props: {
    selectedDate: Date | null;
    setSelectedDate: (date: Date | null) => void;
  };
}

const DCWizardPicker: React.FC<DatePickerProps> = ({ input, props }) => {
  const { selectedDate, setSelectedDate } = props;

  function setDate(selectedDate: Date) {
    setSelectedDate(selectedDate);
    const value = calculateDaysDifference(selectedDate);
    input.onChange({ target: { value } });
  }

  return (
    <SingleDatePicker testId="date-picker" selectedDate={selectedDate} setSelectedDate={setDate} />
  );
};

const HARD_LIMIT_DC = 200;
const SOFT_LIMIT_DC = 100;

const poundsToPennies = (pounds: number) => Math.round(pounds * 100);
const penniesToPounds = (pounds: number) => pounds / 100;

const supplyTypes: string[] = ['ELECTRICITY', 'GAS'];
interface MeterSupplyTypes {
  ELECTRICITY: string;
  GAS: string;
  [key: string]: string;
}
const supplyTypesMap: MeterSupplyTypes = {
  ELECTRICITY: 'Electric',
  GAS: 'Gas',
};

export const ModalForm: React.FunctionComponent<Props> = ({
  balances,
  accountId,
  onClose,
  debts,
  customerVulnerabilities,
}) => {
  const [mtrRequest, setMtrRequest] = React.useState<RequestMeterInfo[]>([]);
  const [formSubmitted, setFormSubmitted] = React.useState(false);

  const [dcMutationV3, dcMutationResult] = useMutation<Results, Variables>(
    addDiscretionaryCreditV3Mutation,
    {
      refetchQueries: ['accountBalanceQuery'],
      awaitRefetchQueries: true,
    },
  );

  const [selectedDate, setSelectedDate] = React.useState<Date | null>(null);
  const [recommendedGAS, setGasRecommended] = React.useState<null | RecommendedDCInfo>(null);
  const [recommendationOverride, setRecommendationOverride] = React.useState({
    GAS: false,
    ELECTRICITY: false,
  });

  const [recommendedELECTRICITY, setElecRecommended] = React.useState<null | RecommendedDCInfo>(
    null,
  );

  // these values should be set once and then only read because we will no longer attempt to get recommendations once it fails
  // unless the form is reset (currently we dont treat a failed query do we want to treat it the same as a successful that cant recommend)
  const [invalidRecommendations, setInvalidRecommendations] = React.useState({
    GAS: false,
    ELECTRICITY: false,
  });

  const { session } = useContext(ClientStateContext);

  // used to track differences in form states to prevent infinite loops
  const currentDaysRequired = React.useRef<null | string>(null);
  const currentGasRepaymentRate = React.useRef<null | string>(null);
  const currentElecRepaymentRate = React.useRef<null | string>(null);

  const formattedVulnerabilities = formatVulnerabilities(customerVulnerabilities);

  // we combine with debts here so we can get the repayment rate in the meters array
  const meters = balances.map((balance) => ({
    ...(debts.find(({ msn }) => balance.msn === msn) || {}),
    ...balance,
  })) as MeterInfo[];

  const recommendations = {
    [BalanceType.GAS]: recommendedGAS,
    [BalanceType.ELECTRICITY]: recommendedELECTRICITY,
  };

  const metersInRightMode = meters.filter((meter) => meter.adjustable);

  const nonEligibleMeters = meters.filter((meter) => !meter.adjustable);

  const nonEligibleSupplyTypes: string[] = nonEligibleMeters.map((meter) => meter.type);
  const duplicateMeters: string[] = [];
  if (metersInRightMode.length > 1) {
    const seen: string[] = [];
    metersInRightMode.forEach((meter) => {
      if (!seen.includes(meter.type)) seen.push(meter.type);
      else duplicateMeters.push(meter.type);
    });
  }

  // we can't deal with duplicate meter types
  const eligibleMeters = metersInRightMode.filter((meter) => !duplicateMeters.includes(meter.type));

  // convenience for instances where an array isn't desirable
  const eligibleMetersMap = new Map(eligibleMeters.map((meter) => [meter.type, meter]));
  const elecEligible = eligibleMeters.find((meter) => meter.type === BalanceType.ELECTRICITY);
  const gasEligible = eligibleMeters.find((meter) => meter.type === BalanceType.GAS);

  interface DisclaimerArgs {
    metersSelected: string;
    totalDCGAS: string;
    totalDCELECTRICITY: string;
    repaymentRateGAS: string;
    repaymentRateELECTRICITY: string;
  }

  // bits of text for the page
  const makeDisclaimer = (args: DisclaimerArgs) => {
    const { metersSelected } = args;
    if (!recommendations) return '';
    let amount;
    let repaymentRate;
    let daysToRepay;
    let type;
    if (metersSelected === 'GAS' || metersSelected === 'ELECTRICITY') {
      const selectedMeter = metersSelected;
      const relevantAmount = args[('totalDC' + selectedMeter) as keyof DisclaimerArgs];
      const relevantRepaymentRate = args[('repaymentRate' + selectedMeter) as keyof DisclaimerArgs];

      amount = `${formatStringAsMoney(relevantAmount)}`;
      repaymentRate = `${formatStringAsMoney(relevantRepaymentRate)}`;
      daysToRepay = getDateXDaysIntoFuture(
        Math.ceil(
          parseFloatWithCommas(relevantAmount) / parseFloatWithCommas(relevantRepaymentRate) || 0,
        ),
      ).toLocaleDateString();
      type = metersSelected.toLowerCase();
    } else {
      const gasdaysToRepay = getDateXDaysIntoFuture(
        Math.ceil(
          parseFloatWithCommas(args.totalDCGAS) / parseFloatWithCommas(args.repaymentRateGAS),
        ),
      ).toLocaleDateString();
      const elecdaysToRepay = getDateXDaysIntoFuture(
        Math.ceil(
          parseFloatWithCommas(args.totalDCELECTRICITY) /
            parseFloatWithCommas(args.repaymentRateELECTRICITY),
        ),
      ).toLocaleDateString();

      amount = `${formatStringAsMoney(args.totalDCGAS)} and ${formatStringAsMoney(
        args.totalDCELECTRICITY,
      )}`;
      repaymentRate = `${formatStringAsMoney(
        args.repaymentRateGAS,
      )} (gas) and ${formatStringAsMoney(args.repaymentRateELECTRICITY)} (electric)`;
      daysToRepay = `${gasdaysToRepay} (gas) and ${elecdaysToRepay} (electric)`;
      type = `gas and electric respectively`;
    }
    return `The ${amount} additional support credit for ${type} we are lending you today will be repaid at a rate of ${repaymentRate}
      per day. This loan should be repaid by ${daysToRepay}. However, if you're using your emergency credit then repayment of this loan will pause until your account is in credit again, so it may take longer to repay. If you need further support during this time but haven't acted on the advice we've given or have accrued further significant debt we may not be able to help you. It's important you understand this is a formal repayment agreement, this conversation will be noted on your account, do you understand?`;
  };

  const turnToUs = 'https://www.turn2us.org.uk/';
  const stepChange = 'https://www.stepchange.org/';

  const handleCopy = (event: React.MouseEvent<HTMLButtonElement>) => {
    if ((event.target as any).name == 'turnToUs') navigator.clipboard.writeText(turnToUs);
    if ((event.target as any).name == 'stepChange') navigator.clipboard.writeText(stepChange);
  };

  const fetchingTimer = React.useRef<number | undefined>();
  const [jobDetails, setJobDetails] = React.useState<JobDetails[]>([]);

  const clearTimer = () => {
    clearInterval(fetchingTimer.current);
    fetchingTimer.current = undefined;
  };

  const [getJobDetails] = useLazyQuery<JobDetailsQuery, JobDetailsQueryVariables>(jobDetailsQuery, {
    onCompleted: (data) => {
      const dataJobIndex = jobDetails.findIndex(
        (job) => job.jobId === data.job.jobId && job.state === 'PENDING',
      );
      if (dataJobIndex < 0) return;
      jobDetails[dataJobIndex] = {
        ...data.job,
        utrn:
          !data.job.utrn || data.job.utrn === '' ? jobDetails[dataJobIndex].utrn : data.job.utrn,
        msn: jobDetails[dataJobIndex].msn,
      };
      setJobDetails(jobDetails);
      setFormSubmitted(false);
    },
  });

  const timerFunction = (jobs: JobDetails[]) => {
    const jobDetails = jobs.filter((job) => job.state === 'PENDING');
    if (jobDetails.length) {
      getJobDetails({
        variables: {
          jobId: jobDetails[jobDetails.length === 1 || Math.random() < 0.5 ? 0 : 1].jobId,
        },
      });
    } else {
      if (fetchingTimer.current) clearTimer();
    }
  };

  React.useEffect(() => {
    if (fetchingTimer.current) clearTimer();
    fetchingTimer.current = window.setInterval(timerFunction, 3000, jobDetails);
  }, [jobDetails]);

  React.useEffect(() => {
    return () => {
      if (fetchingTimer.current) {
        clearTimer();
      }
    };
  }, []);

  return (
    <Final.Form
      mutators={{
        setState: (
          args: unknown[],
          state: MutableState<Record<string, unknown>>,
          tools: Tools<Record<string, unknown>>,
        ) => {
          tools.changeValue(state, args[0] as string, () => args[1]);
        },
      }}
      onSubmit={async (args: ModalForm) => {
        const {
          metersSelected,
          mobileNumber,
          daysRequired,
          contactChannel,
          totalDCGAS,
          totalDCELECTRICITY,
          repaymentRateGAS,
          repaymentRateELECTRICITY,
        } = args;
        setFormSubmitted(true);
        // Filter out __typename property from the recommendation object
        const filteredRecommendation = filterTypename(recommendations);

        const meterRequests = [];
        const gasMeter = eligibleMetersMap.get(BalanceType.GAS);
        if ((metersSelected === BalanceType.GAS || metersSelected === 'both') && gasMeter) {
          const repaymentRate = poundsToPennies(parseFloatWithCommas(repaymentRateGAS));
          const usualRepaymentRateToSet = parseFloatWithCommas(
            String(gasMeter.usualRepaymentRate || repaymentRate),
          );
          const gasDCAmount = poundsToPennies(parseFloatWithCommas(totalDCGAS));
          meterRequests.push({
            amount: toMoneyFormat(gasDCAmount),
            fuel: gasMeter.type,
            msn: gasMeter.msn,
            mpxn: gasMeter.mpxn,
            repaymentRate: toMoneyFormat(repaymentRate * 1000),
            usualRepaymentRate: toMoneyFormat(usualRepaymentRateToSet * 1000),
          });
        }

        const elecMeter = eligibleMetersMap.get(BalanceType.ELECTRICITY);
        if (
          (metersSelected === BalanceType.ELECTRICITY || metersSelected === 'both') &&
          elecMeter
        ) {
          const repaymentRate = poundsToPennies(parseFloatWithCommas(repaymentRateELECTRICITY));
          const elecDCAmount = poundsToPennies(parseFloatWithCommas(totalDCELECTRICITY));
          const usualRepaymentRateToSet = parseFloatWithCommas(
            String(elecMeter.usualRepaymentRate || repaymentRate),
          );
          meterRequests.push({
            amount: toMoneyFormat(elecDCAmount),
            fuel: elecMeter.type,
            msn: elecMeter.msn,
            mpxn: elecMeter.mpxn,
            repaymentRate: toMoneyFormat(repaymentRate * 1000),
            usualRepaymentRate: toMoneyFormat(usualRepaymentRateToSet * 1000),
          });
        }

        if (meterRequests.length > 0) {
          setMtrRequest(meterRequests);
          const mutationRes = await dcMutationV3({
            variables: {
              input: {
                meterRequests,
                agentName: session?.user.email || '',
                discretionaryCreditInfo: {
                  accountNo: accountId,
                  channel: contactChannel,
                  clientId: 'retail-payg-portal-service',
                  disclaimer: makeDisclaimer(args),
                  mobileNumber,
                  daysRequired: parseInt(daysRequired),
                  meterRecommendations: filteredRecommendation,
                },
                externalReference: uuid.v4(),
              },
            },
          });
          const DC: JobDetails[] = [];
          mutationRes.data?.addDiscretionaryCreditV3?.forEach((dc) => {
            if (dc?.jobIds?.balanceAdjustmentId && !dc.error)
              DC.push({
                __typename: 'JobDetails',
                jobId: dc.jobIds.balanceAdjustmentId,
                utrn: null,
                state: 'PENDING',
                msn: dc.msn || '',
                errors: null,
              });
          });
          if (DC.length) {
            setJobDetails(DC);
          }
        }
        usabillaPopup('trigger', 'manual trigger');
      }}
      validate={(values) => {
        // through validation we make sure that the submit button isnt temporarily disabled when the agent
        // fills in the first part of the form
        const filled = ['metersSelected', 'contactChannel', 'daysRequired', 'utrnSelection'].every(
          (key) => key in values,
        );
        // here we just want the form to be invalid so the submit button is disabled
        return filled ? undefined : { metersSelected: 'missing fields' };
      }}
    >
      {({ form, handleSubmit, invalid, values, errors, dirty, touched, dirtyFields }) => {
        React.useEffect(() => {
          if (!!gasEligible && !elecEligible) {
            form.mutators.setState('metersSelected', 'GAS');
          } else if (!gasEligible && !!elecEligible) {
            form.mutators.setState('metersSelected', 'ELECTRICITY');
          }
        }, []);
        const [getRecommendedGas /* recommendedDCQuery */] = useLazyQuery<
          recommendedDCQueryResults,
          recommendedDCQueryVariables
        >(recommendedDiscretionaryCreditQuery, {
          onCompleted: (data) => {
            if (data?.recommendedDiscretionaryCredit?.dailyChargeCover?.value) {
              const newRecommendation = data.recommendedDiscretionaryCredit;
              const newRecommendedAmount = penceToPound(newRecommendation.repaymentRate.value);
              setGasRecommended(newRecommendation);
              currentDaysRequired.current = values.daysRequired;
              currentGasRepaymentRate.current = newRecommendedAmount;
              form.mutators.setState('repaymentRateGAS', newRecommendedAmount);
              form.mutators.setState('totalDCGAS', penniesToPounds(newRecommendation.total.value));
              setRecommendationOverride((prev) => ({ ...prev, GAS: false }));
            } else {
              setInvalidRecommendations((invalidRecommendations) => ({
                ...invalidRecommendations,
                GAS: true,
              }));
            }
          },
        });
        const [getRecommendedElec] = useLazyQuery<
          recommendedDCQueryResults,
          recommendedDCQueryVariables
        >(recommendedDiscretionaryCreditQuery, {
          onCompleted: (data) => {
            if (data?.recommendedDiscretionaryCredit?.dailyChargeCover?.value) {
              const newRecommendation = data.recommendedDiscretionaryCredit;
              const newRecommendedAmount = penceToPound(newRecommendation.repaymentRate.value);
              setElecRecommended(newRecommendation);
              currentElecRepaymentRate.current = newRecommendedAmount;
              currentDaysRequired.current = values.daysRequired;
              form.mutators.setState('repaymentRateELECTRICITY', newRecommendedAmount);
              form.mutators.setState(
                'totalDCELECTRICITY',
                penniesToPounds(newRecommendation.total.value),
              );
              setRecommendationOverride((prev) => ({ ...prev, ELECTRICITY: false }));
            } else {
              setInvalidRecommendations((invalidRecommendations) => ({
                ...invalidRecommendations,
                ELECTRICITY: true,
              }));
            }
          },
        });
        const getRecommendedGasDebounced = debounce(
          (gasMeter: MeterInfo, repaymentRateGAS: string, daysRequired: string) => {
            getRecommendedGas({
              variables: {
                input: {
                  accountId,
                  currentRepaymentRate: {
                    currency: Currency.GBP,
                    value:
                      parseFloatWithCommas(
                        poundsToPennies(parseFloatWithCommas(repaymentRateGAS)).toFixed(2),
                      ) ||
                      gasMeter?.repaymentRate ||
                      75,
                  },
                  toDate: format(
                    getDateXDaysIntoFuture(parseInt(daysRequired)).toISOString(),
                    'YYYY-MM-DD',
                  ),
                  type: gasMeter.type,
                  mpxn: gasMeter.mpxn,
                },
              },
            });
          },
          1000,
        );
        const getRecommendedElecDebounced = debounce(
          (elecMeter: MeterInfo, repaymentRateELECTRICITY: string, daysRequired: string) => {
            getRecommendedElec({
              variables: {
                input: {
                  accountId,
                  currentRepaymentRate: {
                    currency: Currency.GBP,
                    value:
                      parseFloatWithCommas(
                        (parseFloatWithCommas(repaymentRateELECTRICITY) * 100).toFixed(2),
                      ) ||
                      elecMeter?.repaymentRate ||
                      75,
                  },
                  toDate: format(
                    getDateXDaysIntoFuture(parseInt(daysRequired)).toISOString(),
                    'YYYY-MM-DD',
                  ),
                  type: elecMeter.type,
                  mpxn: elecMeter.mpxn,
                },
              },
            });
          },
          1000,
        );
        const calcDaysToRepay = (values: ModalForm, meterType: BalanceType) =>
          Math.ceil(
            parseFloatWithCommas(values[('totalDC' + meterType) as keyof ModalForm]) /
              parseFloatWithCommas(values[('repaymentRate' + meterType) as keyof ModalForm]),
          );
        const renderDCWarning = (name: string) => {
          const typeName = name as 'totalDCGAS' | 'totalDCELECTRICITY';
          React.useEffect(() => {
            if (parseFloatWithCommas(values[typeName]) > SOFT_LIMIT_DC) {
              trackFinancialSupport({
                agentName: session?.user?.email || '',
                discretionaryCreditInfo: {
                  accountNo: accountId,
                  amount: name === 'totalDCGAS' ? values.totalDCGAS : values.totalDCELECTRICITY,
                  repaymentRate:
                    name === 'totalDCGAS'
                      ? values.repaymentRateGAS
                      : values.repaymentRateELECTRICITY,
                  channel: values.contactChannel,
                  supplyType: values.metersSelected,
                  clientId: 'retail-payg-portal-service',
                },
              });
            }
          }, [values[typeName]]);
          if (errors[name] && (touched?.[name] || dirtyFields[name])) {
            return (
              <div data-test={name + '-message'} className="DCWarningMessage">
                <Icon className="attention" />
                {errors[name]}
              </div>
            );
          } else if (parseFloatWithCommas(values[typeName]) > SOFT_LIMIT_DC) {
            return (
              <div data-test={name + '-message'} className="DCWarningMessage">
                <Icon className="warning sign" />
                Confirm with financial support
              </div>
            );
          } else {
            return null;
          }
        };

        const DCFieldStatus = (name: string): DCFeedBackScheme => {
          const typedName = name as 'totalDCGAS' | 'totalDCELECTRICITY';

          if (errors[name] && (touched?.[name] || dirtyFields[name])) return 'error';
          if (parseFloatWithCommas(values[typedName]) > SOFT_LIMIT_DC) return 'warning';
          return 'ok';
        };

        const showResultsPage =
          dcMutationResult.data?.addDiscretionaryCreditV3 ||
          (dcMutationResult.error &&
            !findBadUserInputErrors(dcMutationResult.error.graphQLErrors).hasBadUserInputErrors);

        const isInvalidNumberFormat =
          dcMutationResult.error &&
          findBadUserInputErrors(dcMutationResult.error.graphQLErrors).hasMobileNumberError;

        return (
          <PortalModal
            submitButtonTitle="Add Additional Support Credit"
            headerTitle="Additional Support Credit Wizard"
            onClose={onClose}
            onSubmit={handleSubmit}
            reset={() => {
              setElecRecommended(null);
              setGasRecommended(null);
              setSelectedDate(null);
              currentDaysRequired.current = null;
              currentGasRepaymentRate.current = null;
              currentElecRepaymentRate.current = null;
              form.reset();
            }}
            loadingDC={dcMutationResult.loading}
            resetDisabled={dcMutationResult.called || !dirty}
            submitDisabled={invalid || !!showResultsPage}
            hideDisabledAction={!!showResultsPage}
          >
            <Form onSubmit={handleSubmit}>
              {formSubmitted && (
                <Dimmer inverted active={true}>
                  <Loader />
                </Dimmer>
              )}
              {!showResultsPage ? (
                <div>
                  {supplyTypes.map((supply) => {
                    return (
                      <>
                        {duplicateMeters.includes(supply) ? (
                          <>
                            <div key={supply} className="BasicCard">
                              <Message
                                key={supply}
                                negative
                                visible={true}
                                warning={false}
                                attached="top"
                              >
                                <Icon className="attention" />{' '}
                                <span>{supplyTypesMap[supply]} | MSN :</span>
                                {meters.map((meter) => {
                                  return (
                                    <>
                                      {meter.type === supply ? (
                                        <span>&nbsp; {meter.msn}&nbsp; |</span>
                                      ) : null}
                                    </>
                                  );
                                })}
                                <span>
                                  <b>&nbsp; More than 1 active meter</b>
                                </span>
                              </Message>
                              <div style={{ margin: '0 2rem' }}>
                                <ul className="InvalidModeLists">
                                  <li>Additional support credit should be processed manually</li>
                                  <li>Check which meter is in the property</li>
                                  <li>Remove inactive meter(s) from the system</li>
                                </ul>
                              </div>
                            </div>
                          </>
                        ) : (
                          nonEligibleSupplyTypes.includes(supply) && (
                            <div key={supply} className="BasicCard">
                              <Message negative visible={true} warning={false} attached="top">
                                <Icon className="attention" /> <span>{supplyTypesMap[supply]}</span>{' '}
                                &nbsp;| &nbsp;MSN :&nbsp;
                                {nonEligibleMeters.map((meter) => {
                                  return (
                                    <>
                                      {meter.type === supply ? (
                                        <span>&nbsp; {meter.msn}&nbsp; |</span>
                                      ) : null}
                                    </>
                                  );
                                })}
                                <b>
                                  &nbsp; Invalid meter mode &nbsp;: &nbsp;Customer is in credit mode
                                </b>{' '}
                              </Message>
                              <div style={{ margin: '0 2rem' }}>
                                <ul className="InvalidModeLists">
                                  <li>Check the meter modes on Bol</li>
                                  <li>Has the customer recently has meters fitted?</li>
                                  <li>Is the customer with OVO or Boost</li>
                                </ul>
                              </div>
                            </div>
                          )
                        )}
                      </>
                    );
                  })}
                  {meters.map((meter) => (
                    <>
                      {!duplicateMeters.includes(meter.type) && meter.adjustable && (
                        <Message
                          positive
                          visible={true}
                          warning={false}
                          data-test="discretionary-credit-info-message"
                          key={meter.msn}
                        >
                          <Icon className="check circle" />
                          <span>{supplyTypesMap[meter.type]}</span>
                          &nbsp;| &nbsp;MSN : {meter.msn} &nbsp;
                          <b>Eligible for Additional Support Credit</b>
                        </Message>
                      )}
                    </>
                  ))}
                  {formattedVulnerabilities && (
                    <>
                      <Message
                        size="small"
                        icon
                        info
                        data-test="customer-vulnerabilities"
                        id="modal-message"
                      >
                        <Message.Content>
                          <Message.Header>
                            <Icon name="info circle" />
                            Consider the following customer vulnerabilities when adding additional
                            support credit:
                          </Message.Header>
                          <span data-test="vulnerabilities-list">{formattedVulnerabilities}</span>
                        </Message.Content>
                      </Message>
                    </>
                  )}
                  <Grid columns={2}>
                    <Grid.Row>
                      <Grid.Column width={8}>
                        Supply type
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <label
                            id="electric-label"
                            data-test="electric-label"
                            style={{ display: 'contents' }}
                          >
                            <Final.Field
                              component="input"
                              type="radio"
                              className="Checkbox"
                              name="metersSelected"
                              value={BalanceType.ELECTRICITY}
                              disabled={!elecEligible}
                              aria-labelledby="electric-label"
                              validate={(metersSelected) =>
                                !metersSelected || metersSelected?.length === 0
                                  ? 'Please select at least one eligible meter to apply additional support credit to'
                                  : undefined
                              }
                            />
                            Electric &nbsp;
                          </label>
                          <label
                            id="gas-label"
                            data-test="gas-label"
                            style={{ display: 'contents' }}
                          >
                            <Final.Field
                              component="input"
                              type="radio"
                              className="Checkbox"
                              name="metersSelected"
                              value={BalanceType.GAS}
                              disabled={!gasEligible}
                              aria-labelledby="gas-label"
                            />
                            Gas &nbsp;
                          </label>

                          <label
                            id="both-label"
                            data-test="both-label"
                            style={{ display: 'contents' }}
                          >
                            <Final.Field
                              component="input"
                              type="radio"
                              className="Checkbox"
                              disabled={!(gasEligible && elecEligible)}
                              name="metersSelected"
                              value="both"
                              aria-labelledby="both-label"
                            />
                            Both
                          </label>
                        </div>
                      </Grid.Column>
                      <Grid.Column width={8}>
                        Requested via
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Final.Field
                            component="input"
                            value="WebMessaging"
                            type="radio"
                            className="Checkbox"
                            name="contactChannel"
                            validate={(contactChannel) =>
                              contactChannel?.length == 0
                                ? 'Please select the one communication channel via which the customer requested dicretionary credit'
                                : undefined
                            }
                          />
                          Web Chat &nbsp;
                          <Final.Field
                            component="input"
                            value="Phone"
                            type="radio"
                            className="Checkbox"
                            name="contactChannel"
                          />
                          Phone &nbsp;
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <Grid columns={3}>
                    <Grid.Row>
                      <Grid.Column width={6}>
                        Time required (1-31 days){' '}
                        <Final.Field
                          validate={(val) =>
                            !val || val < 1 || val > 31
                              ? 'Time rewuired must be between 1 and 31 days'
                              : undefined
                          }
                          name="daysRequired"
                          component={(fieldProps) => (
                            <DCWizardPicker
                              input={{
                                name: fieldProps.input.name,
                                value: fieldProps.input.value,
                                onChange: fieldProps.input.onChange,
                              }}
                              props={{
                                selectedDate,
                                setSelectedDate,
                              }}
                            />
                          )}
                        />
                      </Grid.Column>
                      &emsp;&thinsp;
                      <Grid.Column width={1}></Grid.Column>
                    </Grid.Row>
                  </Grid>
                  {/* in order to show the dimmer when transitioning from the first part of the form to the second */}
                  {'contactChannel' in values &&
                    'daysRequired' in values &&
                    'contactChannel' in values &&
                    Object.keys(values).length === 3 && (
                      <Dimmer inverted active={true}>
                        <Loader />
                      </Dimmer>
                    )}
                  {(recommendedGAS ||
                    recommendedELECTRICITY ||
                    invalidRecommendations.ELECTRICITY ||
                    invalidRecommendations.GAS) && (
                    <>
                      {eligibleMeters
                        .filter((meter) => [meter.type, 'both'].includes(values.metersSelected))
                        .map((meter) => (
                          <Table key={meter.msn} basic>
                            <Table.Header>
                              <Table.Row warning={invalidRecommendations[meter.type]}>
                                <Table.HeaderCell>
                                  {invalidRecommendations[meter.type] ? (
                                    <b>Insufficient data to do calulations</b>
                                  ) : (
                                    <b>
                                      {' '}
                                      Calculator:{' '}
                                      <span style={{ textTransform: 'capitalize' }}>
                                        {meter.type}
                                      </span>
                                    </b>
                                  )}
                                </Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                              </Table.Row>
                            </Table.Header>

                            <Table.Body>
                              {!invalidRecommendations[meter.type] && (
                                <>
                                  <Table.Row>
                                    <Table.Cell>Cover daily usage</Table.Cell>
                                    <Table.Cell data-test="asc-daily-charge">
                                      {formatMoney(recommendations[meter.type]?.dailyChargeCover)}
                                    </Table.Cell>
                                  </Table.Row>
                                  <Table.Row>
                                    <Table.Cell>
                                      Bring balance above £0
                                      <Popup
                                        trigger={
                                          <Icon
                                            className={'question circle icon PopupLeftMargin'}
                                          />
                                        }
                                        content={
                                          'This amount includes EMC, Standing Charge build up and any missed debt (DRR) recovery which may have built up. This needs to be repaid before the customer can get back on supply.'
                                        }
                                        position="top center"
                                      />
                                    </Table.Cell>
                                    <Table.Cell data-test="asc-above-0">
                                      {formatMoney(
                                        recommendations[meter.type]?.bringBalanceAboveZero,
                                      )}
                                    </Table.Cell>
                                  </Table.Row>
                                </>
                              )}
                              <Table.Row
                                className={`DCColorScheme ${
                                  DCFieldStatus('totalDC' + meter.type) || ''
                                }`}
                              >
                                <Table.Cell>
                                  Additional Support Credit to be applied{' '}
                                  {recommendationOverride[meter.type] && (
                                    <span>(recommendation overridden)</span>
                                  )}
                                </Table.Cell>
                                <Table.Cell>
                                  <Grid.Row>
                                    <Final.Field
                                      name={'totalDC' + meter.type}
                                      value={formatMoney(recommendations[meter.type]?.total)}
                                      subscription={{ value: true }}
                                      formatOnBlur={true}
                                      format={(val) => fomatTo2DecimalPlaces(val)}
                                      validate={(val) => {
                                        if (!val) return 'missing additional support credit amount';
                                        if (isNaN(parseFloatWithCommas(val)))
                                          return 'additional support credit amount must be a valid number';
                                        if (parseFloatWithCommas(val) == 0)
                                          return 'additional support credit amount must be more than £0';
                                        if (parseFloatWithCommas(val) > HARD_LIMIT_DC)
                                          return `additional support credit must be less than £${HARD_LIMIT_DC}`;
                                        if (!Number.isInteger(parseFloatWithCommas(val)))
                                          return `amount must be a whole number`;
                                        return undefined;
                                      }}
                                    >
                                      {(props) => {
                                        return (
                                          <>
                                            <div
                                              style={{ display: 'flex' }}
                                              className="ui left icon input"
                                            >
                                              <Icon name="pound sign" />
                                              <input
                                                name={props.input.name}
                                                type="text"
                                                value={props.input.value}
                                                autoComplete="off"
                                                onBlur={props.input.onBlur}
                                                onFocus={props.input.onFocus}
                                                onChange={(args) => {
                                                  let penniesValue: number;
                                                  try {
                                                    penniesValue =
                                                      parseFloatWithCommas(args.target.value) * 100;
                                                  } catch {
                                                    setRecommendationOverride((prev) => ({
                                                      ...prev,
                                                      [meter.type]: true,
                                                    }));
                                                    return;
                                                  }
                                                  if (
                                                    recommendations[meter.type]?.total.value !==
                                                    Math.round(penniesValue)
                                                  ) {
                                                    setRecommendationOverride((prev) => ({
                                                      ...prev,
                                                      [meter.type]: true,
                                                    }));
                                                  } else {
                                                    setRecommendationOverride((prev) => ({
                                                      ...prev,
                                                      [meter.type]: false,
                                                    }));
                                                  }
                                                  return props.input.onChange(args);
                                                }}
                                              ></input>
                                            </div>
                                            {renderDCWarning('totalDC' + meter.type)}
                                          </>
                                        );
                                      }}
                                    </Final.Field>
                                  </Grid.Row>
                                </Table.Cell>
                              </Table.Row>
                              <Table.Row className="DCRepaymentRate">
                                <Table.Cell>Daily Repayment rate</Table.Cell>
                                <Table.Cell>
                                  <Grid.Row>
                                    <div style={{ display: 'flex' }} className="ui left icon input">
                                      <Icon name="pound sign" />
                                      <Final.Field
                                        name={'repaymentRate' + meter.type}
                                        type="text"
                                        component="input"
                                        formatOnBlur={true}
                                        format={(val) => {
                                          return fomatTo2DecimalPlaces(val);
                                        }}
                                        value={
                                          recommendations[meter.type]?.repaymentRate.value ||
                                          meter.repaymentRate ||
                                          '0.75'
                                        }
                                        validate={(val: string) => {
                                          if (!val) return 'missing repayment rate amount';
                                          if (isNaN(parseFloatWithCommas(val)))
                                            return 'repayment rate amount must be a valid number';
                                          if (parseFloatWithCommas(val) < 0.5)
                                            return '£0.50 minimum repayment rate';
                                          if (parseFloatWithCommas(val) > 5)
                                            return '£5.00 maximum repayment rate';
                                          return undefined;
                                        }}
                                        className={`RepaymentRate ${
                                          errors[`repaymentRate${meter.type}`] ? 'Limit' : ''
                                        }`}
                                        autoComplete="off"
                                      />
                                    </div>
                                  </Grid.Row>
                                  <Grid.Row>
                                    {errors[`repaymentRate${meter.type}`] &&
                                      (touched?.[`repaymentRate${meter.type}`] ||
                                        dirtyFields?.[`repaymentRate${meter.type}`]) && (
                                        <Table.Cell error className="Warning" width={'6'}>
                                          <span data-test={`repaymentRate${meter.type}-error`}>
                                            <Icon className="attention" />
                                            {errors[`repaymentRate${meter.type}`]}
                                          </span>
                                        </Table.Cell>
                                      )}
                                  </Grid.Row>
                                </Table.Cell>
                              </Table.Row>
                              {!isNaN(calcDaysToRepay(values, meter.type)) && (
                                <Table.Row>
                                  <Table.Cell>Debt repaid in</Table.Cell>
                                  <Table.Cell>
                                    {calcDaysToRepay(values, meter.type)} days
                                  </Table.Cell>
                                </Table.Row>
                              )}
                            </Table.Body>
                          </Table>
                        ))}
                      {eligibleMeters.length > 0 && values.metersSelected?.length > 0 && (
                        <>
                          <Table basic>
                            <Table.Header>
                              <Table.Row>
                                <Table.HeaderCell width={16}>
                                  <b> Disclaimer for the customer</b>
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                  <button
                                    className="Copy"
                                    onClick={() => {
                                      void navigator.clipboard.writeText(makeDisclaimer(values));
                                    }}
                                    type="button"
                                    name="disclaimer"
                                  >
                                    copy
                                  </button>{' '}
                                </Table.HeaderCell>
                              </Table.Row>
                            </Table.Header>

                            <Table.Body>
                              <Table.Row>
                                <Table.Cell width={16}>{makeDisclaimer(values)}</Table.Cell>
                              </Table.Row>
                            </Table.Body>
                          </Table>
                          <Table basic>
                            <Table.Header>
                              <Table.Row>
                                <Table.HeaderCell width={3}>
                                  <b> Useful links</b>
                                </Table.HeaderCell>
                                <Table.HeaderCell width={2}></Table.HeaderCell>
                                <Table.HeaderCell width={10}></Table.HeaderCell>
                              </Table.Row>
                            </Table.Header>

                            <Table.Body>
                              <Table.Row>
                                <Table.Cell>Turn to us</Table.Cell>
                                <Table.Cell>{turnToUs}</Table.Cell>
                                <Table.Cell>
                                  <button
                                    className="Copy"
                                    onClick={handleCopy}
                                    name="turnToUs"
                                    type="button"
                                  >
                                    copy
                                  </button>
                                </Table.Cell>
                              </Table.Row>
                              <Table.Row>
                                <Table.Cell>Step change</Table.Cell>
                                <Table.Cell>{stepChange}</Table.Cell>
                                <Table.Cell>
                                  <button
                                    className="Copy"
                                    onClick={handleCopy}
                                    name="stepChange"
                                    type="button"
                                  >
                                    copy
                                  </button>{' '}
                                </Table.Cell>
                              </Table.Row>
                            </Table.Body>
                          </Table>
                          <Grid.Column width={10} className="PhoneNumberBlock">
                            Send the customer a text message with the UTRN?
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: '13px',
                                marginBottom: '13px',
                              }}
                            >
                              <label
                                id="utrn-label-yes"
                                data-test="utrn-label-yes"
                                style={{ display: 'flex', alignItems: 'center' }}
                              >
                                <Final.Field
                                  name="utrnSelection"
                                  type="radio"
                                  value="yes"
                                  className="Checkbox"
                                  component="input"
                                  initialValue="yes"
                                />
                                &nbsp;&nbsp;Yes &nbsp;&nbsp;&nbsp;&nbsp;
                              </label>
                              <label id="utrn-label-no" data-test="utrn-label-no">
                                <Final.Field
                                  name="utrnSelection"
                                  type="radio"
                                  value="no"
                                  className="Checkbox"
                                  component="input"
                                  onClick={() => form.change('mobileNumber', '')}
                                />
                                &nbsp;&nbsp;No &nbsp;
                              </label>
                            </div>
                            {form.getState().values.utrnSelection === 'yes' && (
                              <Grid>
                                <Grid.Row>
                                  <Grid.Column width={6}>
                                    <span>Mobile number</span>
                                    <Grid.Column className="MarginTop4px">
                                      <Final.Field
                                        component="input"
                                        type="text"
                                        data-test="mobile-number"
                                        className={`Mobile ${isInvalidNumberFormat ? 'Error' : ''}`}
                                        placeholder="Enter mobile number"
                                        name="mobileNumber"
                                        validate={(val) => {
                                          if (!val) return 'mobile number is required';
                                          const { isValid } = validateAndFormatPhoneNumber(val);
                                          return isValid && /^\d+$/.test(val)
                                            ? undefined
                                            : 'invalid mobile number only numeric characters allowed';
                                        }}
                                        value="test"
                                      />
                                      {(isInvalidNumberFormat ||
                                        (errors.mobileNumber && touched?.mobileNumber)) && (
                                        <Grid.Column className="MobileWarning">
                                          <span>
                                            <Icon className="attention" />
                                            {errors.mobileNumber}
                                          </span>
                                        </Grid.Column>
                                      )}
                                    </Grid.Column>
                                  </Grid.Column>
                                </Grid.Row>
                              </Grid>
                            )}
                          </Grid.Column>
                        </>
                      )}
                    </>
                  )}
                </div>
              ) : (
                <ResultsPage
                  meterRequests={mtrRequest}
                  jobDetails={jobDetails}
                  dcResults={dcMutationResult.data?.addDiscretionaryCreditV3 || []}
                  allPassed={!dcMutationResult.error}
                  utrnSelection={form.getState().values.utrnSelection}
                />
              )}
              <Final.FormSpy
                onChange={debounce((props) => {
                  const { repaymentRateGAS, repaymentRateELECTRICITY, daysRequired } = props.values;

                  const relevantGasMeter = eligibleMeters.find(
                    (meter) => meter.type === BalanceType.GAS,
                  );

                  if (
                    relevantGasMeter &&
                    daysRequired &&
                    (daysRequired !== currentDaysRequired.current ||
                      repaymentRateGAS !== currentGasRepaymentRate.current) &&
                    !props.errors.repaymentRateGAS
                  ) {
                    getRecommendedGasDebounced(relevantGasMeter, repaymentRateGAS, daysRequired);
                  }
                  const relevantElecMeter = eligibleMeters.find(
                    (meter) => meter.type === BalanceType.ELECTRICITY,
                  );
                  if (
                    relevantElecMeter &&
                    daysRequired &&
                    (daysRequired !== currentDaysRequired.current ||
                      repaymentRateELECTRICITY !== currentElecRepaymentRate.current) &&
                    !props.errors.repaymentRateELECTRICITY
                  ) {
                    getRecommendedElecDebounced(
                      relevantElecMeter,
                      repaymentRateELECTRICITY,
                      daysRequired,
                    );
                  }
                  currentDaysRequired.current = daysRequired;
                  currentGasRepaymentRate.current = repaymentRateGAS;
                  currentElecRepaymentRate.current = repaymentRateELECTRICITY;
                }, 1000)}
              />
            </Form>
          </PortalModal>
        );
      }}
    </Final.Form>
  );
};
